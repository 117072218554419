import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import IconLocation from "./icons/location"

const FilterDepartmentsHonduras = () => {

  const data = useStaticQuery(
    graphql`
      query TermsDepartmentsHonduras {
        allCountriesJson(filter: {slug: {eq: "hondures"}}) {
          edges {
            node {
              departments {
                name
                slug
              }
            }
          }
        }
        dataJson(slug: {eq: "filter-departments"}) {
          fragment_1
        }
      }
    `
  )

  const allTerms = data.allCountriesJson.edges
  const dataFragments = data.dataJson

  return (
    <Box
      className='filter-deparments'
      sx={{
        h3: {
          color: `hondures`,
          fontSize: 1,
          fontWeight: `body`,
          mb: 2,
        },
        ul: {
          listStyle: `none`,
          m: 0,
          p: 0,
        },
        li : {
          marginTop: 1,
          marginBottom: 1,
        },
        a: {
          borderBottom: `none`,
          fontSize: 1,
          ":hover": {
            borderBottom: `none`,
          },
        },
        ".deparment": {
          alignItems: `center`,
          svg: {
            height: 5,
            fill: `hondures`,
            mr: 2,
          },
        }
      }}
    >
      <Themed.h3>{dataFragments.fragment_1}</Themed.h3>
      <Box as={`ul`}>
        {allTerms.map(({ node }) => (
            node.departments.map( item => (
              <Themed.li key={item.slug}>
                <Flex className={`deparment ${item.slug}`}>
                  <IconLocation />
                  <Themed.a as={Link} to={`/${item.slug}`}>
                    {item.name}
                  </Themed.a>
                </Flex>
              </Themed.li>
            ))
          )
        )}
      </Box>
    </Box>
  )
}

export default FilterDepartmentsHonduras